import currency from "currency.js";
import { fetchActionsUtil, formatForQty } from "../../utils/helpers";
import {
  BucketIcon,
  ClockIcon,
  InvoiceDashboardIcon,
  InvoiceOverDue,
  InvoicePaidIcon,
  InvoiceUpcomingIcon,
  InvoiceUpnaidIcon,
  LineChartIcon,
  OverdueInvoiceStatsIcon,
  PaidInvoiceStatIcon,
  SideBarToggleIcon,
  UnpaidInvoiceStatsIcon,
  UpcomingInvoiceStatsIcon,
} from "../Icons";
import { Link, Outlet } from "react-router-dom";
import "../../assets/scss/customInvoiceWrapper.scss";
import InvoiceAnalysisChart from "./InvoiceAnalysisChart";
import InvoiceAnalysisPieChart from "./InvoiceAnalysisPieChart";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
const items = [
  { name: "Dashboard", icon: <InvoiceDashboardIcon />, link: "" },
  {
    name: "Paid",
    icon: <InvoicePaidIcon />,
    link: "/invoices/paid-invoices",
  },
  {
    name: "Unpaid",
    icon: <InvoiceUpnaidIcon />,
    link: "/invoices/unpaid-invoices",
  },
  {
    name: "Upcoming",
    icon: <InvoiceUpcomingIcon />,
    link: "/invoices/upcoming-invoices",
  },
  {
    name: "Overdue",
    icon: <InvoiceOverDue />,
    link: "/invoices/overdue-invoices",
  },
  {
    name: "Scheduled Transactions",
    icon: <ClockIcon />,
    link: "/invoices/scheduled-transactions",
  },
  {
    name: "Quotaion",
    icon: <ClockIcon />,
    link: "/sales-and-invoicing/quotation",
  },
];
export default function InvoiceLayout({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const { backendUrl, token, user: authUser } = useAuth();

  const { data, refetch, isFetching, isSuccess } = useQuery(
    ["FETCH_INVOICE_STATISTICS_DASHBOARD"],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/invoice/dasboard-layout-stats?Staff_ID=${
          authUser?.invexVersion === "InvexInvoicing" ? authUser?.Staff_ID : ""
        }`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <div className="custom-invoice-wrapper " style={{ minHeight: "90vh" }}>
        <div className={"d-flex"}>
          <div className={isOpen ? "dashboard-menu" : " dashboard-menu-close"}>
            <div className="">
              <ul>
                {items.map((item, index) => (
                  <Link
                    to={item.link}
                    className="btn d-flex    align-items-center gap-1 custom-link"
                    title={!isOpen ? item?.name : ""}
                  >
                    <div className="icon">{item.icon}</div>
                    {isOpen && <span>{item.name}</span>}
                  </Link>
                ))}
                {/* <button
                  className="  bg-primary"
                  onClick={() => setIsOpen((prev) => !prev)}
                >
                  <SideBarToggleIcon />
                </button> */}
              </ul>
            </div>
          </div>

          <div className="custom-invoice-stats">
            <section className=" custom-stat-items">
              <section className={`custom-stats mb-3  `}>
                {/* <div className="custom-stat  border rounded-4">
                  <div className="icon icon-1">
                    <PaidInvoiceStatIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(Number(data?.data?.paid), {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Paid</span>
                  </div>
                </div>

                <div className="custom-stat  border rounded-4">
                  <div className="icon bg-light-blue">
                    <UpcomingInvoiceStatsIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(Number(data?.data?.upcoming), {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Upcoming</span>
                  </div>
                </div>

                <div className="custom-stat  border rounded-4">
                  <div className="icon ">
                    <OverdueInvoiceStatsIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(Number(data?.data?.overdue), {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Overdue</span>
                  </div>
                </div>

                <div className="custom-stat  border rounded-4">
                  <div className="icon  icon-2">
                    <UnpaidInvoiceStatsIcon />
                  </div>
                  <div className="custom-details">
                    <p>
                      {currency(Number(data?.data?.unpaid), {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()}
                    </p>
                    <span>Unpaid</span>
                  </div>
                </div> */}

                <Link to="/invoices/paid-invoices">
                  <div className="custom-stat  border rounded-4">
                    <div className="icon icon-1">
                      <PaidInvoiceStatIcon />
                    </div>

                    <div className="custom-details">
                      <p>
                        {currency(Number(data?.data?.paid), {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <span>Paid</span>
                    </div>
                  </div>
                </Link>

                <Link to={"/invoices/upcoming-invoices"}>
                  <div className="custom-stat  border rounded-4">
                    <div className="icon bg-light-blue">
                      <UpcomingInvoiceStatsIcon />
                    </div>
                    <div className="custom-details">
                      <p>
                        {currency(Number(data?.data?.upcoming), {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <span>Upcoming</span>
                    </div>
                  </div>
                </Link>

                <Link to={"/invoices/overdue-invoices"}>
                  <div className="custom-stat  border rounded-4">
                    <div className="icon ">
                      <OverdueInvoiceStatsIcon />
                    </div>
                    <div className="custom-details">
                      <p>
                        {currency(Number(data?.data?.overdue), {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <span>Overdue</span>
                    </div>
                  </div>
                </Link>

                <Link to={"/invoices/unpaid-invoices"}>
                  <div className="custom-stat  border rounded-4">
                    <div className="icon  icon-2">
                      <UnpaidInvoiceStatsIcon />
                    </div>
                    <div className="custom-details">
                      <p>
                        {currency(Number(data?.data?.unpaid), {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <span>Unpaid</span>
                    </div>
                  </div>
                </Link>
              </section>

              {children}
            </section>
          </div>
        </div>

        {/* {Nav && <div className="nav-bar">{Nav}</div>} */}
        <Outlet />
      </div>
    </>
  );
}
