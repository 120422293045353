// import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import {
  QueryClient,
  useQuery,
  useQueryClient,
  QueryCache,
  useMutation,
} from "react-query";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import "./../assets/scss/scoped/login.scoped.scss";
import {
  LoginImageOne,
  LoginImageTwo,
  LogoMeduim,
  OrnamentOne,
  OrnamentTwo,
  SlideShowIndex,
} from "./Icons";
import { useFormik } from "formik";
import { initialGeneralSettings, waitFor } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import { setDefaultLocale } from "react-datepicker";
import { services, backendApis, appSettings, IS_HR } from "../config";
import { useStoreActions } from "easy-peasy";
import { useEffectOnce } from "../utils/hooks";
import { loginHRSite } from "../utils/loginHRSite";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import ConfirmDialog from "./ConfirmDialogue";
import { SendPasswordResetLink } from "./SendPasswordResetLinkModal";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import Cookies from "universal-cookie";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import OtpModal from "./Login/OtpModal";
import platform, { description } from "platform";
import { GoogleLogin } from "@react-oauth/google";

const options = backendApis.map((el) => ({
  ...el,
  value: el.name,
  label: el.name,
}));

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);

  const createStaff = async (payload) => {
    let response = await fetch(`${backendApis[0].url}/api/auth/auth-register`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createStaffMutation = useMutation((payload) => createStaff(payload), {
    onSuccess: ({ message, data }) => {
      // toast.success(message);
      formik.resetForm();

      ConfirmDialog({
        type: "success",
        title: message,
        choice: false,
      });
    },
    onError: ({ message }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      firstName: yup
        .string()
        .required("required")
        .min(2),
      lastName: yup
        .string()
        .required("required")
        .min(2),
      password: yup
        .string()
        .required("required")
        .min(8),
      email: yup
        .string()
        .required("required")
        .email(),
    }),
    onSubmit: (values) => {
      createStaffMutation.mutate(values);
    },
  });

  const handleLoginSuccess = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      // Send the token to your backend for validation
      /*  const response = await axios.post('http://localhost:5000/api/auth/google', { token: credential });
       console.log('Server Response:', response.data); */
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="login">
      <div className="form-area">
        <Link to={"/dashboard"} className="logo">
          <LogoMeduim />
        </Link>

        <div className="content">
          <div className="text-center">
            <h1>Sign Up</h1>
            <p>Create your Account.</p>
          </div>

          {process.env?.REACT_APP_DEPLOYMENT_TYPE === "shared" && (
            <>
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={() => console.error("Login Failed")}
                text={"signup_with"}
              />

              <div class="d-flex justify-content-between align-items-center gap-3 my-3">
                <hr className="flex-grow-1" />
                <span>Or sign up with email</span>
                <hr className="flex-grow-1" />
              </div>
            </>
          )}

          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            disabled={createStaffMutation.isLoading}
          >
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="login-label">First name</Form.Label>
                  <Form.Control
                    className="ga-form-control"
                    type="text"
                    placeholder="Enter your first name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.firstName && !!formik.errors.firstName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="login-label">Last name</Form.Label>
                  <Form.Control
                    className="ga-form-control"
                    type="text"
                    placeholder="Enter your last name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.lastName && !!formik.errors.lastName
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="login-label">Email</Form.Label>
              <Form.Control
                className="ga-form-control"
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 position-relative">
              <Form.Label className="login-label">Password</Form.Label>
              <Form.Control
                className="ga-form-control"
                type={showPassword ? "text" : "password"}
                placeholder="••••••••"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>

              <Button
                variant=""
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "2rem",
                  transform: `scale(0.8)`,
                }}
                onClick={(e) => {
                  e.target.blur();
                  setShowPassword(!showPassword);
                }}
                className="no-focus text-light"
              >
                {!showPassword ? <EyeOffOutlineIcon /> : <EyeOutlineIcon />}
              </Button>
            </Form.Group>

            <Button
              variant="primary"
              className="w-100 p-2"
              disabled={createStaffMutation.isLoading}
              type="submit"
            >
              {createStaffMutation.isLoading ? "Please wait…" : "Sign Up"}
            </Button>
          </Form>

          <p className="my-3">
            Already have an account?{" "}
            <Link to={"/login"} className="text-primary">
              Sign In
            </Link>
          </p>
        </div>

        <a
          className="owner"
          href="https://excellentbridge.com"
          target="_blank"
          rel="noreferrer"
        >
          ©{new Date().getFullYear()} ExcellentBridge Technologies - version{" "}
          {process.env.REACT_APP_VERSION}
        </a>
      </div>
      <div className="bg-primary slide-show">
        <OrnamentTwo className="ornament-two" />
        <OrnamentOne className="ornament-one" />

        <div className="image">
          <div className="slide-image-holder">{<LoginImageOne />}</div>
          {/*  <div className="slide-image-holder-two">
            <LoginImageTwo />
          </div> */}
          <div>
            <h2 className="text-white">Access insightful data</h2>
            <p>
              Get direct access to key information with customizable dashboard.{" "}
              <br /> Powerful tools for data and analytics, integration and AI.
            </p>
          </div>

          <SlideShowIndex />
        </div>
      </div>
    </div>
  );
}
